<template>
  <div>
    <b-datepicker
      v-bind="dateRange"
      ref="datepicker"
      :key="'date-picker' + renderKey"
      :value="date"
      :date-formatter="dateFormatter"
      :date-parser="dateParser"
      :disabled="field.properties.basic.isDisabled"
      :placeholder="field.properties.basic.hasPlaceholder ? formatPlaceholder(field.properties.basic.placeholder) : ''"
      :close-on-click="!field.properties.basic.hasTimePicker"
      :mobile-native="false"
      :nearby-month-days="false"
      size="is-small"
      expanded
      editable
      locale="de-DE"
      @input="changeDate($event)"
    >
      <template
        v-if="field.properties.basic.hasTimePicker"
        #default
      >
        <div class="is-flex is-justify-content-center">
          <b-timepicker
            :value="date"
            :hour-format="hourFormat"
            inline
            @input="changeDate($event)"
          />
        </div>
      </template>
    </b-datepicker>
    <BaseInput
      :key="`date_${uuid.v4()}_${formattedDate}`"
      :mask="maskConfig"
      :value="formattedDate"
      class="is-hidden"
      @mask-value-update="updateDatePicker"
    />
    <BaseInput
      :key="`time_${uuid.v4()}_${time}`"
      :mask="timeMaskConfig"
      :value="time"
      class="is-hidden"
      @mask-value-update="updateTimePicker"
    />
  </div>
</template>

<script >
import { computed, ref, onMounted, onUnmounted, watch, nextTick } from '@vue/composition-api';
import moment from 'moment-timezone';
import parseFormat from 'moment-parseformat';
import { parseExpression } from '@/helpers/ExpressionParser';
import { delay } from '@/helpers/Utils';
import BaseInput from './generics/BaseInput.vue';
import { uuid } from 'vue-uuid';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const renderKey = ref(0);
  const datepicker = ref(null);
  const datePickerFormat = props.field.properties.basic.format || 'DD.MM.YYYY';
  const formattedDate = ref('');
  const time = ref('');
  const date = computed(() => {
    const inputDate = props.values[props.field.id];
    if (inputDate) {
      return typeof inputDate === 'string' ? new Date(inputDate) : inputDate;
    }
    return null;
  });
  const maskConfig = computed(() => {
    const fullFormat = props.field.properties.basic.format || 'DD.MM.YYYY';
    const [dateFormat, _timeFormat] = fullFormat.split(/\s+/);
    if (!dateFormat) {
      return {};
    }
    let delimiters = dateFormat.match(/\W/g) || ['.'];
    let delimiter = delimiters[0];
    const datePattern = dateFormat.split(/\W/).map(part => {
      if (part.toLowerCase().includes('d')) {
        return 'd';
      }
      if (part.toLowerCase().includes('m')) {
        return 'm';
      }
      if (part.toLowerCase().includes('y')) {
        return 'Y';
      }
      return '';
    }).filter(part => part);
    return {
      date: true,
      delimiter: delimiter,
      datePattern
    };
  });
  const timeMaskConfig = computed(() => {
    const fullFormat = props.field.properties.basic.format || 'DD.MM.YYYY';
    const [_dateFormat, timeFormat] = fullFormat.split(/\s+/);
    if (!timeFormat) {
      return {};
    }

    // Define default time delimiters and patterns
    let timeDelimiter = ':';
    let timePattern = [];

    // Generate the time pattern based on the format
    timePattern = timeFormat.split(/:/).map(part => {
      if (part.toLowerCase().includes('h')) {
        return part.length === 2 ? 'HH' : 'H'; // Adjust for hour format (24-hour or 12-hour)
      }
      if (part.toLowerCase().includes('m')) {
        return 'MM';
      }
      if (part.toLowerCase().includes('s')) {
        return 'SS';
      }
      return '';
    }).filter(part => part);

    // Assuming the delimiter for time is always ':'
    timeDelimiter = ':';
    return {
      time: true,
      delimiter: timeDelimiter,
      pattern: timePattern
    };
  });
  const dateTimeRange = computed(() => {
    const {
      properties: {
        validation: {
          isRestrictedFromDynamic,
          restrictedFrom,
          restrictedTo,
          isRestrictedToDynamic
        }
      }
    } = props.field;
    const computedMinDate = computeDateTimeLimit(restrictedFrom, isRestrictedFromDynamic);
    const computedMaxDate = computeDateTimeLimit(restrictedTo, isRestrictedToDynamic);
    return {
      ...(computedMinDate ? {
        minDate: computedMinDate
      } : {}),
      ...(computedMaxDate ? {
        maxDate: computedMaxDate
      } : {})
    };
  });
  const dateRange = computed(() => {
    const range = {};
    const {
      minDate,
      maxDate
    } = dateTimeRange.value;
    if (minDate instanceof Date) {
      range.minDate = new Date(minDate);
      range.minDate.setHours(0, 0, 0, 0);
    }
    if (maxDate instanceof Date) {
      range.maxDate = new Date(maxDate);
      range.maxDate.setHours(0, 0, 0, 0);
    }
    return range;
  });
  const hourFormat = computed(() => {
    const fullFormat = props.field.properties.basic.format;
    if (fullFormat?.indexOf('h') !== -1) {
      return '12';
    } else {
      return '24';
    }
  });

  // TO-DO's: Check how time range is implemented in buefy's datetimepicker
  // const timeRange = computed(() => {
  //     const { minDate, maxDate } = dateRange.value;
  //     let range = {};
  //     if (date.value instanceof Date && minDate && date.value.getTime() < minDate.getTime()) {
  //         range.minTime = dateTimeRange.value.minDate;
  //     }
  //     if (date.value instanceof Date && maxDate && date.value.getTime() > maxDate.getTime()) {
  //         range.maxTime = dateTimeRange.value.maxDate;
  //     }
  //     return range;
  // });

  /**
   * @param {Date} updatedDate 
   */
  const changeDate = async updatedDate => {
    if (updatedDate instanceof Date) {
      const {
        minDate,
        maxDate
      } = dateTimeRange.value;
      // restrict date range (if required) since buefy date picker doesn't do it for dates directly typed through input
      if (minDate && updatedDate.getTime() < minDate.getTime()) {
        updatedDate = minDate;
      } else if (maxDate && updatedDate.getTime() > maxDate.getTime()) {
        updatedDate = maxDate;
      }
    }
    emit('update', {
      value: updatedDate
    });
    await delay(0);
    // force render date picker as input inside buefy's datepicker have a stale state issue (only happens in case of directly typing date in input more than once)
    renderKey.value++;
  };
  const computeDateTimeLimit = (rawValue, isDynamic) => {
    /** @type {Date | null} */
    let datePickerLimit = null;
    if (isDynamic) {
      datePickerLimit = parseExpression(rawValue, props.values, 'strip').value;
    } else {
      datePickerLimit = rawValue || null;
    }
    if (datePickerLimit && typeof datePickerLimit === 'string') {
      // converting date string to Date object as required by date picker
      const userTimeZone = moment.tz.guess();
      datePickerLimit = moment.tz(datePickerLimit, userTimeZone).toDate();
    }
    return datePickerLimit;
  };
  const dateFormatter = time => {
    const userTimeZone = moment.tz.guess();
    const outputDate = moment.tz(time, userTimeZone);
    return outputDate.format(datePickerFormat);
  };
  const dateParser = dateString => {
    const userTimeZone = moment.tz.guess();
    const isValidDateFormat = checkDateFormat(dateString);
    if (isValidDateFormat) {
      const parsedDate = moment.tz(dateString, datePickerFormat, userTimeZone).toDate();
      return parsedDate;
    } else {
      return null;
    }
  };
  const checkDateFormat = dateString => {
    let parsedDateFormat = parseFormat(dateString?.split(' ')?.[0] || '');
    return parsedDateFormat === datePickerFormat.split(' ')?.[0];
  };
  const updateDatePicker = value => {
    const inputElement = datepicker.value?.$el.querySelector('input');
    if (inputElement) {
      const tempArr = [...inputElement.value.split(' ')];
      tempArr[0] = value;
      inputElement.value = tempArr.join(' ');
    }
  };
  const updateTimePicker = value => {
    const inputElement = datepicker.value?.$el.querySelector('input');
    if (inputElement) {
      const tempArr = [...inputElement.value.split(' ')];
      if (tempArr?.[1]) {
        tempArr[1] = value;
        inputElement.value = tempArr.join(' ');
      }
    }
  };
  const onInput = event => {
    const inputVal = event.target.value;
    if (!inputVal) return;
    const inputArr = inputVal.split(' ');
    if (inputArr?.[0]) {
      formattedDate.value = inputArr[0];
    }
    if (inputArr?.[1]) {
      time.value = inputArr[1];
    }
  };
  const formatPlaceholder = dateString => {
    const date = moment(dateString);
    if (date.isValid()) {
      return date.format(props.field.properties.basic.format || 'DD.MM.YYYY');
    } else {
      return dateString;
    }
  };
  const attachInputListener = () => {
    const inputElement = datepicker.value?.$el.querySelector('input');
    if (inputElement) {
      inputElement.addEventListener('input', onInput);
    }
  };
  const detachInputListener = () => {
    const inputElement = datepicker.value?.$el.querySelector('input');
    if (inputElement) {
      inputElement.removeEventListener('input', onInput);
    }
  };
  watch(renderKey, async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      detachInputListener();
      await nextTick();
      attachInputListener();
    }
  });
  onMounted(() => {
    attachInputListener();
  });
  onUnmounted(() => {
    detachInputListener();
  });
  return {
    uuid,
    renderKey,
    datepicker,
    formattedDate,
    time,
    date,
    maskConfig,
    timeMaskConfig,
    dateRange,
    hourFormat,
    changeDate,
    dateFormatter,
    dateParser,
    updateDatePicker,
    updateTimePicker,
    formatPlaceholder
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.datepicker {
    &.no-timepicker {
        .datepicker-footer {
            display: none;
        }
    }
}
</style>
